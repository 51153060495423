export const FETCH_USER = 'fetch-user';
export const FETCH_PERMISSIONS = 'fetch-permissions';
export const SHOW_MESSAGE = 'show-message';
export const DASHBOARD_TOP_PERFORMERS_EVENT = 'dashboard-top-performers-event';
export const DASHBOARD_OVERALL_PERFORMANCE_EVENT = 'dashboard-overall-performance';
export const DASHBOARD_AGENT_COMMISSIONS_EVENT = 'dashboard-agent-commissions';
export const DASHBOARD_MERCHANT_COMMISSIONS_EVENT = 'dashboard-merchant-commissions';
export const DASHBOARD_WHITELABEL_COMMISSIONS_EVENT = 'dashboard-whitelabel-commissions';
export const DASHBOARD_PENDING_REFERRALS_EVENT = 'dashboard-pending-referrals';
export const DASHBOARD_PENDING_AGENTS_EVENT = 'dashboard-pending-agents';
