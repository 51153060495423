import React, { Component } from 'react';
import Select from "react-select";

class CustomSelect extends Component {
  state = {};

  sortItems = (items) => {
    items.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));

    return items;
  };

  render() {
    const { value, required, clearable, onChange, options, className, disabled } = this.props;

    return (
      <Select
        className={ className }
        value={ (options && options.length && options.find(option => option.value.toString() === value.toString())) || "" }
        isDisabled={ disabled }
        required={ required }
        isClearable={ clearable }
        isSearchable={ true }
        onChange={ (event) => onChange(event) }
        styles={ {
          control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: "44px",
            cursor: "pointer"
          }),
          menu: (baseStyles, state) => ({
            ...baseStyles,
            margin: 0,
            zIndex: 2
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            cursor: "pointer"
          })
        } }
        options={ this.sortItems(options) }
      />
    );
  }
}

export default CustomSelect;
