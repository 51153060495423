import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";

import AffiliateLinksIcon from '../../icons/methods';
import ComponentHeader from "../../componentHeader";
import Select from "../Select";
import Spinner from '../../Spinner';

import CopyIcon from "../../../assets/images/copy-icon.png";

import '../../../assets/css/affiliateLinks.css';
import '../../../assets/css/trustbucks/affiliateLinks.scss';
import '../../../assets/css/t365/affiliateLinks.scss';

import { whiteLabel } from '../../../config';
import { crudActions } from "../../../services/crudActions";

class AffiliateLinks extends Component {
  state = {
    isLoading: true,
    products: [{
      value: "agnt",
      label: "Agent"
    }, {
      value: "mcnt",
      label: "Merchant"
    }, {
      value: "wl",
      label: "White Label"
    }],

    product: "agnt",
    links: {
      agnt: "",
      mcnt: "",
      wl: ""
    }
  };

  componentDidMount() {
    crudActions.get(`v1/agent/links`).then(
      (links) => {
        if (links) {
          this.setState({
            links: {
              agnt: links.agentRL,
              mcnt: links.merchantRL,
              wl: links.whiteLabelRL
            },
            isLoading: false
          });
        }
      }
    );
  };

  onProductChange = (newProduct) => {
    this.setState({
      product: newProduct.value
    });
  };

  fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg); // eslint-disable-line no-console
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err); // eslint-disable-line no-console
    }

    document.body.removeChild(textArea);
  };

  copyTextToClipboard = () => {
    const { product, links } = this.state;
    const copiedLink = links[product];

    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(copiedLink);
      return;
    }
    navigator.clipboard.writeText(copiedLink).then(function() {
      console.log('Async: Copying to clipboard was successful!'); // eslint-disable-line no-console
    }, function(err) {
      console.error('Async: Could not copy text: ', err); // eslint-disable-line no-console
    });
  };

  render() {
    const { product, products, links, isLoading } = this.state;
    return (
      <Row flexGrow={ 1 } className='module affiliate-links' vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title="Referral links"
            img={ AffiliateLinksIcon[whiteLabel].userManagement }/>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading className={ whiteLabel }>
                  <Panel.Title >
                    REFERRAL LINKS
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div> : <div className={ `${whiteLabel} panel-content` } style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                      <Column vertical='start' alignSelf='start' className="input-column">
                        <label> Product </label>
                        <Select id="product"
                          name="product"
                          value={ product || '' }
                          required={ true }
                          clearable={ false }
                          onChange={ (value) => this.onProductChange(value) }
                          options={ products }
                        />
                      </Column>
                      <Column vertical='start' alignSelf='start' className="double-input-column">
                        <label> Signup Link </label>
                        <input
                          className="form-control link-input"
                          value={ product ? links[product] : '' }
                          disabled
                        />
                        <img
                          data-tip="Copy link"
                          className="copy-icon"
                          src={ CopyIcon }
                          onClick={ this.copyTextToClipboard }
                          alt="copy"
                        />
                      </Column>
                    </Row>
                  </div> }
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }
}

export default AffiliateLinks;
