import React, { Component } from 'react';
import { Panel } from "react-bootstrap";

import Spinner from '../../../Spinner';
import Table from "../../Table";

import '../../../../assets/css/apiData.css';

import { DASHBOARD_PENDING_REFERRALS_EVENT } from '../../../../actions/types';
const store =  require('../../../../reducers/index');

class DashBoardPendingReferralsComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      pendingReferrals: undefined,
      loading: true,
      socket: false,
      columns: [{
        value: "name",
        label: "Name"
      }, {
        value: "date",
        label: "Date"
      }, {
        value: "product",
        label: "Product"
      }, {
        value: "status",
        label: "Status"
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_PENDING_REFERRALS_EVENT && this.props.thisMonth && state.pendingReferrals.socket) {
        this.setState({ pendingReferrals: state.pendingReferrals, loading: false, socket: state.pendingReferrals.socket });
        return;
      }

      if (state.update === DASHBOARD_PENDING_REFERRALS_EVENT && !state.pendingReferrals.socket) {
        this.setState({ pendingReferrals: state.pendingReferrals, loading: false, socket: state.pendingReferrals.socket });
      }
    });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  render() {
    const { pendingReferrals, loading, columns } = this.state;

    let pendingReferralsWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> PENDING REFERRALS </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && pendingReferrals) {
      pendingReferralsWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> PENDING REFERRALS </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ pendingReferrals }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return pendingReferralsWrap;
  }

}

export default DashBoardPendingReferralsComponent;