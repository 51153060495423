import React, {Component} from 'react';

import '../../../../assets/css/apiData.css';
import ComponentHeader from "../../../componentHeader";
import dashboardIcon from '../../../../components/icons/methods';

import { whiteLabel } from '../../../../config';

class LastUpdateComponent extends Component {
  state = {
    pageTitle: 'Dashboard'
  };

  render() {
    const { pageTitle } = this.state;
    const { lastUpdate } = this.props;

    return <ComponentHeader title={ pageTitle } lastUpdate={ lastUpdate } img={ dashboardIcon[whiteLabel].dashboard }/>;
  }
}

export default LastUpdateComponent;