import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Header from './Header';
import SideBar from './SideBar';
import Module from './Module';
import { Column, Row } from 'simple-flexbox';
import Login from './modules/login/Login';
import ScrollToTop from './functional-components/ScrollToTop';

import "bootstrap/dist/css/bootstrap.min.css";
import '../assets/css/t365/main.scss';
import '../assets/css/trustbucks/main.scss';
import '../assets/css/main.css';
import { whiteLabel } from '../config';

class App extends Component {
  state = {
    userLoggedIn: false
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
      this.setState({ userLoggedIn: true });
    }
  }

  render() {
    const { userLoggedIn } = this.state;

    return (
      <div style={ { zoom: userLoggedIn ? 0.9 : 1} }>
        <BrowserRouter>
          <ScrollToTop>
            <Route>
              <Column className= { `${whiteLabel} content ${userLoggedIn ? 'logged-in' : 'non-logged-in'}` }>
                <Header />
                <Row flexGrow={ 1 }>

                  { userLoggedIn &&
                    <Column className={ `${whiteLabel} sidebarColumn` }>
                      <SideBar />
                    </Column>
                  }

                  <Column className= { `${whiteLabel} contentColumn` } style={ {width: '100%'} }>
                    { userLoggedIn ?  <Module /> : <Login /> }
                  </Column>
                </Row>
              </Column>
            </Route>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;