import React, { Component } from 'react';
import { Panel } from "react-bootstrap";

import Spinner from '../../../Spinner';
import Table from "../../Table";

import '../../../../assets/css/apiData.css';

import { toFormattedNumber } from "../../../../utils/utils.js";
import { DASHBOARD_MERCHANT_COMMISSIONS_EVENT } from '../../../../actions/types';
const store =  require('../../../../reducers/index');

class DashBoardMerchantCommissionsComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      merchantCommissions: undefined,
      loading: true,
      socket: false,
      columns: [{
        value: "productName",
        label: "Name"
      }, {
        value: "productCommission",
        label: "Commission",
        isNumber: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_MERCHANT_COMMISSIONS_EVENT && this.props.thisMonth && state.merchantCommissions.socket) {
        this.setState({ merchantCommissions: state.merchantCommissions, loading: false, socket: state.merchantCommissions.socket });
        return;
      }

      if (state.update === DASHBOARD_MERCHANT_COMMISSIONS_EVENT && !state.merchantCommissions.socket) {
        this.setState({ merchantCommissions: state.merchantCommissions, loading: false, socket: state.merchantCommissions.socket });
      }
    });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { merchantCommissions } = this.state;

    if (!merchantCommissions) {
      return [];
    }

    return merchantCommissions.map(elem => {
      return {
        productName: elem.productName,
        productType: elem.productType,
        productCommission: elem.currencySymbol + " " + toFormattedNumber(elem.productCommission),
      };
    });
  };

  render() {
    const { merchantCommissions, loading, columns } = this.state;

    let merchantCommissionsWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> MERCHANT COMMISSIONS </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && merchantCommissions) {
      merchantCommissionsWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> MERCHANT COMMISSIONS </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return merchantCommissionsWrap;
  }

}

export default DashBoardMerchantCommissionsComponent;