import React, { Component } from 'react';
import {  Panel } from "react-bootstrap";

import Spinner from '../../../Spinner';
import Table from "../../Table";

import '../../../../assets/css/apiData.css';

import { toFormattedNumber } from "../../../../utils/utils.js";
import { DASHBOARD_OVERALL_PERFORMANCE_EVENT } from '../../../../actions/types';
const store =  require('../../../../reducers/index');

class DashBoardOverallPerformanceComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overallPerformance: undefined,
      loading: true,
      socket: false,
      columns: [{
        value: "label",
        label: "Channel"
      }, {
        value: "commission",
        label: "Commission",
        isNumber: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_OVERALL_PERFORMANCE_EVENT && this.props.thisMonth && state.overallPerformance.socket) {
        this.setState({ overallPerformance: state.overallPerformance, loading: false, socket: state.overallPerformance.socket });
        return;
      }

      if (state.update === DASHBOARD_OVERALL_PERFORMANCE_EVENT && !state.overallPerformance.socket) {
        this.setState({ overallPerformance: state.overallPerformance, loading: false, socket: state.overallPerformance.socket });
      }
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { overallPerformance } = this.state;

    if (!overallPerformance) {
      return [];
    }

    return overallPerformance.map(elem => {
      return {
        label: elem.label,
        clicks: elem.clicks,
        isTotal: elem.label === "Total",
        signUps: elem.signUps,
        commission: elem.currencySymbol + toFormattedNumber(elem.commission)
      };
    }).filter(e => e.label !== 'White Labels');
  };

  render() {
    const { overallPerformance, loading, columns } = this.state;

    let overallPerformanceWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> OVERALL PERFORMANCE </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && overallPerformance) {
      overallPerformanceWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> OVERALL PERFORMANCE </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return overallPerformanceWrap;
  }
}

export default DashBoardOverallPerformanceComponent;