import React, {Component} from 'react';

import { NavLink } from 'react-router-dom';
import { Row } from 'simple-flexbox';

class Table extends Component {
  state = {
    sortBy: "",
    sortDirection: "DESC"
  };

  componentDidMount() {
    const { defaultSortBy } = this.props;
    if (defaultSortBy) {
      this.setState({
        sortBy: defaultSortBy
      });
    }
  };

  onSortClick = (field) => {
    let { sortDirection } = this.state;

    const sortBy = field;
    if (sortDirection === "DESC") {
      sortDirection = "ASC";
    } else {
      sortDirection = "DESC";
    }

    this.setState({
      sortBy,
      sortDirection
    });
  };

  sortedData = () => {
    const { data, columns } = this.props;
    const { sortBy, sortDirection } = this.state;
    if (!sortBy) {
      return data;
    }

    const filteredData = data.filter(elem => !elem.isTotal);
    const sortedByColumn = columns.find(column => column.value === sortBy);

    const getNumber = (element) => {
      const numberPattern = /\d+(\.\d+)?$/g;
      if (typeof element === "number") {
        return element;
      }

      const foundNumbers = element.replace(/,/g, "").match(numberPattern);
      return foundNumbers && foundNumbers.length ? parseFloat(foundNumbers[0]) : '';
    };

    filteredData.sort((elemA, elemB) => {
      const propertyA = sortedByColumn.isNumber ? getNumber(elemA[sortBy]) : elemA[sortBy];
      const propertyB = sortedByColumn.isNumber ? getNumber(elemB[sortBy]) : elemB[sortBy];

      if (propertyA > propertyB) {
        return sortDirection === "ASC" ? 1 : -1;
      } else if (propertyA < propertyB) {
        return sortDirection === "ASC" ? -1 : 1;
      }

      return 0;
    });

    return filteredData.concat(data.filter(elem => elem.isTotal));
  };

  render() {
    const { sortBy } = this.state;
    const { columns, data, isStriped, headerColor } = this.props;

    if (!columns || !data) {
      return <Row><span/></Row>;
    }

    return (
      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className="table-component">
        <table className={ `table ${isStriped ? "table-striped" : ""}` }>
          <thead style={ headerColor ? { backgroundColor: headerColor } : null }>
            <tr>
              {
                columns.map(column => {
                  return <th
                    key={ column.value + column.label }
                    style={ { pointerEvents: column.isAction ? "none" : "",
                      textAlign: column.rightAligned ? 'right' : column.centerAligned ? 'center' : '' } }
                    className={ sortBy === column.value ? 'header-active' : '' }
                    onClick={ () => this.onSortClick(column.value) }>
                    <span className="text-span">
                      { column.label }
                    </span>
                  </th>;
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              this.sortedData().map((dataElem, index) => {
                return <tr key={ index }>
                  {
                    columns.map(column => {
                      return <td
                        key={ column.value + index + column.label }
                        style={ {textAlign: column.rightAligned ? 'right' : column.centerAligned ? 'center' : ''} }>
                        {
                          !column.isAction ? dataElem[column.value] : <NavLink to={ `${column.route}/${dataElem[column.value]}` } className={ column.className }>
                            { column.label }
                          </NavLink>
                        }
                      </td>;
                    })
                  }
                </tr>;
              })
            }
          </tbody>
        </table>
      </Row>
    );
  }
}

export default Table;
