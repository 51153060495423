import React from 'react';
import '../assets/css/spinner.css';

import {whiteLabel} from '../config';
import '../assets/css/t365/spinner.scss';
import '../assets/css/trustbucks/spinner.scss';

const spinner = (props) => (
  <div
    className={ `${whiteLabel} Loader ${props.smallContainer && 'small'}` }
    style={ { color: props.color } }
  >
    Loading...
  </div>
);

export default spinner;