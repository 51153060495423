import { DASHBOARD_TOP_PERFORMERS_EVENT, DASHBOARD_OVERALL_PERFORMANCE_EVENT,
  DASHBOARD_AGENT_COMMISSIONS_EVENT, DASHBOARD_MERCHANT_COMMISSIONS_EVENT,
  DASHBOARD_WHITELABEL_COMMISSIONS_EVENT, DASHBOARD_PENDING_REFERRALS_EVENT,
  DASHBOARD_PENDING_AGENTS_EVENT } from '../actions/types';

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = {}, action) {
  state.update = action.type;
  switch(action.type) {
  case DASHBOARD_TOP_PERFORMERS_EVENT:
    state.topPerformers = action.payload;
    state.topPerformers.socket = action.socket;
    return state;
  case DASHBOARD_OVERALL_PERFORMANCE_EVENT:
    state.overallPerformance = action.payload;
    state.overallPerformance.socket = action.socket;
    return state;
  case DASHBOARD_AGENT_COMMISSIONS_EVENT:
    state.agentCommissions = action.payload;
    state.agentCommissions.socket = action.socket;
    return state;
  case DASHBOARD_MERCHANT_COMMISSIONS_EVENT:
    state.merchantCommissions = action.payload;
    state.merchantCommissions.socket = action.socket;
    return state;
  case DASHBOARD_WHITELABEL_COMMISSIONS_EVENT:
    state.whitelabelCommissions = action.payload;
    state.whitelabelCommissions.socket = action.socket;
    return state;
  case DASHBOARD_PENDING_REFERRALS_EVENT:
    state.pendingReferrals = action.payload;
    state.pendingReferrals.socket = action.socket;
    return state;
  case DASHBOARD_PENDING_AGENTS_EVENT:
    state.pendingAgents = action.payload;
    state.pendingAgents.socket = action.socket;
    return state;
  default:
    return state;
  }
}