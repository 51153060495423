import React, { Component } from 'react';
import { Panel } from "react-bootstrap";

import Spinner from '../../../Spinner';
import Table from "../../Table";

import '../../../../assets/css/trustbucks/apiData.scss';

import { whiteLabel } from '../../../../config';
import { toFormattedNumber } from "../../../../utils/utils.js";
import { DASHBOARD_AGENT_COMMISSIONS_EVENT } from '../../../../actions/types';
const store =  require('../../../../reducers/index');

class DashBoardAgentCommissionsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      css: "",
      agentCommissions: undefined,
      loading: true,
      socket: false,

      columns: [{
        value: "productName",
        label: "Name"
      }, {
        value: "productCommission",
        label: "Commission",
        isNumber: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_AGENT_COMMISSIONS_EVENT && this.props.thisMonth && state.agentCommissions.socket) {
        this.setState({ agentCommissions: state.agentCommissions, loading: false, socket: state.agentCommissions.socket });
        return;
      }

      if (state.update === DASHBOARD_AGENT_COMMISSIONS_EVENT && !state.agentCommissions.socket) {
        this.setState({ agentCommissions: state.agentCommissions, loading: false, socket: state.agentCommissions.socket });
      }
     
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { agentCommissions } = this.state;

    if (!agentCommissions) {
      return [];
    }

    return agentCommissions.map(elem => {
      return {
        productName: elem.productName,
        productType: elem.productType,
        productCommission: elem.currencySymbol + " " + toFormattedNumber(elem.productCommission),
      };
    });
  };

  render() {
    const { agentCommissions, loading, columns } = this.state;

    let agentCommissionsWrap = (
      <Panel className={ whiteLabel === "trustbucks" ? `${whiteLabel}` : "starmids" }>
        <Panel.Heading  className={ whiteLabel === "trustbucks" ? `${whiteLabel}` : "starmids" }>
          <Panel.Title  className={ whiteLabel === "trustbucks" ? `${whiteLabel}` : "starmids" }> AGENT COMMISSIONS </Panel.Title>
        </Panel.Heading>
        <Panel.Body  className={ whiteLabel === "trustbucks" ? `${whiteLabel}` : "starmids" }>
          <Spinner  className={ whiteLabel === "trustbucks" ? `${whiteLabel}` : "starmids" } smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && agentCommissions) {
      agentCommissionsWrap = (
        <Panel style={ { height: '100%' } } className={ whiteLabel === "trustbucks" ? `${whiteLabel}` : "starmids" }>
          <Panel.Heading className={ whiteLabel === "trustbucks" ? `${whiteLabel}` : "starmids" }>
            <Panel.Title className={ whiteLabel === "trustbucks" ? `${whiteLabel}` : "starmids" }> AGENT COMMISSIONS </Panel.Title>
          </Panel.Heading>
          <Panel.Body className={ whiteLabel === "trustbucks" ? `${whiteLabel}` : "starmids" }> 
            <div className="panel-content">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return agentCommissionsWrap;
  }

}

export default DashBoardAgentCommissionsComponent;